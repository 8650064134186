<template>
  <div>
    <b-button variant="primary" @click="addMember">
      {{$t('common.select')}}
    </b-button>
    <b-button v-if="options.length > 0" variant="secondary" class="ml-1" @click="clearMember">
      {{$t('common.clear')}}
    </b-button>

    <div v-if="options.length > 0" class="pb-1 pl-1 pr-1 mt-1 group-edit">
      <b-row>
        <b-col cols="6" v-for="option in options" class="mt-1">
          <div class="group-edit p-6px">
            {{option.memberName}}（{{option.bedNumber}}）
            <feather-icon icon="XIcon" size="20" class="mt-4px cursor-pointer box-close" @click="deleteMember(option.memberID)"/>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'

export default {
  name: "SlotMember",
  components: {
    BButton,
    BRow,
    BCol
  },
  props: {
    rowData: {type: Object},
    facilityId: {type: Number},
  },
  data() {
    return {
      options: []
    }
  },
  watch: {
    facilityId: function (facilityID) {
      this.rowData.members = []
      this.options = []
      this.$emit('changeMemberFacilityId', facilityID)
    }
  },
  mounted() {
    this.options = this.rowData.members
  },
  methods: {
    addMember: function () {
      this.$emit('addMember')
    },
    deleteMember: function (member_id) {
      for (const i in this.options) {
        if (this.options[i].memberID === member_id) {
          this.options.splice(i, 1)
          break
        }
      }
    },
    clearMember: function () {
      this.options = []
    },
    buildData: function () {
      this.rowData['memberIDs'] = []
      for (const i in this.options) {
        this.rowData['memberIDs'].push(this.options[i].memberID)
      }

      return true
    }
  }
}
</script>

<style scoped>

</style>
